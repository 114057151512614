import mapboxgl from 'mapbox-gl';
import classes from "./HomePage.module.scss";
import {carouselImages, galleryArray, prioritiesArray} from "./homepage-data";
import ImagesCarousel from "../../components/images-carousel/ImagesCarousel";
import MainHeading from "../../components/main-heading/MainHeading";
import ImagePlaceholder from "../../components/image-placeholder/ImagePlaceholder";
import PriorityCard from "../../components/priority-card/PriorityCard";
import priorityBackground from "../../assets/images/homepage/priorities-bg-path.svg";
import {ColorScheme} from "../../components/footer/Footer";
import IntersectionAnimate, {
  Animations,
} from "../../components/intersection-animate/IntersectionAnimate";
import {useMediaQuery} from "@uidotdev/usehooks";
import MobileGallery from "../../components/mobile-gallery/MobileGallery";
import useDocumentTitle from "../../hooks/use-document-title";
import peterImage from '../../assets/images/homepage/peter.jpg';
import katkaImage from '../../assets/images/homepage/katka.jpg';
import {useEffect, useRef} from "react";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || '';
const defaultAnimationOffset = "0px 0px -150px 0px";

const HomePage = () => {

  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const lng = 21.26375304907574;
  const lat = 48.972931949015624;
  const zoom = 14;

  useDocumentTitle({title: 'Budo Klub Katai - Úvod'});
  const isMobile = useMediaQuery("only screen and (max-width: 640px)");


  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/navigation-night-v1',
      center: [lng, lat],
      zoom: zoom
    });
    new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current)
  });

  return (
    <>
      <section className={`viewport-section ${classes["welcome-section"]}`}>
        <ImagesCarousel itemsArray={carouselImages} slideDuration={6000}/>
      </section>
      <section className={classes["about-us"]}>
        <div className={`classic-content ${classes["section-content"]}`}>
          <div className={classes["images-part"]}>
            <div className={classes["image-holder"]}>
              <img src={peterImage} alt="Peter"/>
            </div>
            <div className={classes["image-holder"]}>
              <img src={katkaImage} alt="Peter"/>
            </div>
          </div>
          <div className={classes["text-part"]}>
            <IntersectionAnimate
              options={{
                animationType: Animations.FADE_IN_UP,
                offset: defaultAnimationOffset,
              }}
            >
              <MainHeading headingText={"Kto sme?"}/>
            </IntersectionAnimate>
            <IntersectionAnimate
              options={{
                animationType: Animations.FADE_IN_UP,
                offset: defaultAnimationOffset,
              }}
            >
              <p className={"main-desc"}>
                Miesto, kde cvičenie je zábava, kde sa Vaše telá menia na zdravý, flexibilný a funkčný organizmus.
              </p>
            </IntersectionAnimate>
          </div>
        </div>
      </section>
      <section className={classes["priorities"]}>
        <div className={classes["priorities-bg-wrapper"]}>
          <IntersectionAnimate
            options={{
              animationType: Animations.FADE_IN_DOWN,
              animationTime: 1000,
            }}
            styles={{height: "100%"}}
          >
            <img
              src={priorityBackground}
              className={classes["priorities-bg"]}
              alt="priorities background path"
            />
          </IntersectionAnimate>
        </div>
        <div className="classic-content">
          <IntersectionAnimate
            options={{
              animationType: Animations.FADE_IN_UP,
              offset: defaultAnimationOffset,
            }}
          >
            <MainHeading
              headingText={"Čo je našimi prioritami ?"}
              options={{color: "var(--dark-text-color)", lineWidth: 60}}
            />
          </IntersectionAnimate>
          <IntersectionAnimate
            options={{
              animationType: Animations.FADE_IN_UP,
              offset: defaultAnimationOffset,
            }}
          >
            <p className={`main-desc ${classes.description}`}>
              Prinášať pre všetkých skvelý pocit zo športových zážitkov a podeliť sa o náš priestor aj s Vami.
            </p>
          </IntersectionAnimate>
          <div className={`${classes["priorities-holder"]}`}>
            {prioritiesArray.map((item, index) => {
              return (
                <IntersectionAnimate
                  key={index}
                  className={classes["card-holder"]}
                  options={{
                    animationType: Animations.FADE_IN_UP,
                    animationDelay: index * 100,
                  }}
                >
                  <PriorityCard cardIcon={item.img} text={item.text}/>
                </IntersectionAnimate>
              );
            })}
          </div>
        </div>
      </section>
      <section className={classes["find-us"]}>
        <div className="classic-content">
          <MainHeading
            headingText={"Kde nás nájdete?"}
            options={{lineWidth: 60, centeredLine: true}}
          />
          <div className={classes["map-holder"]}>
            <div ref={mapContainer} className={classes["map-container"]}/>
          </div>
        </div>
      </section>
      <section className={classes["gallery"]}>
        <div className="classic-content">
          <IntersectionAnimate
            options={{
              animationType: Animations.FADE_IN_UP,
              offset: defaultAnimationOffset,
            }}
          >
            <MainHeading
              headingText={"Trénujeme s úsmevom!"}
              options={{color: "var(--dark-text-color)"}}
            />
          </IntersectionAnimate>
          <IntersectionAnimate
            options={{
              animationType: Animations.FADE_IN_UP,
              offset: defaultAnimationOffset,
            }}
          >
            <p className="main-desc">
              Budo Klub Katai je miestom, kde sa vaše záľuby a ciele stretávajú.
              Prezrite si všetky naše služby a začnite cestu k lepšiemu ja.
            </p>
          </IntersectionAnimate>
          {!isMobile && (
            <div className={classes["images-holder"]}>
              {[0, 1, 2].map((colIndex) => {
                return (
                  <div key={colIndex} className={classes.col}>
                    {galleryArray
                      .filter((item) => item.colIndex === colIndex)
                      .map((image, index) => {
                        return (
                          <IntersectionAnimate
                            options={{
                              animationType: Animations.FADE_IN_UP,
                            }}
                            key={index}
                          >
                            <div
                              className={classes["image-holder"]}
                              style={{aspectRatio: image.aspectRatio}}
                            >
                              {image.src &&
                                  <img src={image.src} alt="gallery photo"/>
                              }
                              {!image.src &&
                                  <ImagePlaceholder type={ColorScheme.LIGHT}/>
                              }
                            </div>
                          </IntersectionAnimate>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          )}
          {isMobile && (
            <div className={classes["gallery-holder"]}>
              <MobileGallery imagesArray={galleryArray}></MobileGallery>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default HomePage;
